import type { FilterState, Tag } from '~/types'
import type { FilterItem } from '~/types/filter'
import { FilterTabType, ModuleType } from '~/enums'

interface State {
  state: FilterState
  moduleType: ModuleType | undefined
  subModuleType: ModuleType | undefined
  page: number
  tab: FilterTabType
  getPending: Function | undefined
  getCount: Function | undefined
  onCount: Function | undefined
  onSearch: Function | undefined
  onSelect: Function | undefined
}

export const useFilterStore = defineStore('filter', {
  state: (): State => {
    return <State>{
      state: {},
      moduleType: undefined,
      subModuleType: undefined,
      page: 1,
      tab: FilterTabType.List,
      getPending: undefined,
      getCount: undefined,
      onCount: undefined,
      onSearch: undefined,
      onSelect: undefined
    }
  },
  actions: {
    defaultState: (): FilterState => {
      const regionStore = useRegionStore()
      const filterTypes = useFilterTypes()

      return {
        type: filterTypes[0] as FilterItem,
        region: regionStore.getOptions[0],
        types: [] as Tag[],
        rooms: [] as Tag[],
        class: [] as Tag[],
        highway: [] as Tag[],
        purpose_lands: [] as Tag[],
        communications: [] as Tag[],
        heating: [] as Tag[],
        safety: [] as Tag[],
        infrastructures: [] as Tag[],
        village_nears: [] as Tag[],
        roads: [] as Tag[],
        transports: [] as Tag[],
        materials: [] as Tag[],
        interior_decorations: [] as Tag[],
        furniture: [] as Tag[],
        finish: undefined,
        finish_types: [] as Tag[],
        house_material_types: [] as Tag[],
        residential_statuses: [] as Tag[],
        security: [] as Tag[],
        parking: [] as Tag[],
        metro: [],
        search: undefined,
        area: {
          house: {
            from: undefined,
            to: undefined
          },
          plot: {
            from: undefined,
            to: undefined
          },
          general: {
            from: undefined,
            to: undefined
          },
          kitchen: {
            from: undefined,
            to: undefined
          },
          living: {
            from: undefined,
            to: undefined
          }
        },
        mkad: {
          from: undefined,
          to: undefined
        },
        price: {
          from: undefined,
          to: undefined
        }
      }
    }
  }
})
